.heart {
    font-size: 150px;
    color: #E72C3A;
    animation: beat .25s infinite alternate;
    transform-origin: center;
}

/* Heart beat animation */
@keyframes beat{
    to { transform: scale(1.4); }
}